<template>
  <div>
    <div class="show-modify-rate">
      <el-button size="small" type="primary" @click="openAddModifyRate"
        >Add Rate</el-button
      >
    </div>
    <div class="main-section">
      <!-- Right Side Table -->
      <div class="right-side">
        <p class="title">Rate Summary</p>
        <el-table
          style="font-size: 13px; width: 100vw"
          :data="rates"
          height="55vh"
          v-loading="tableLoading"
          :default-sort="{ prop: 'user_type_rate', order: 'ascending' }"
          class="notranslate">
          <el-table-column prop="amount" label="Amount" sortable width="140">
            <!-- format number -->
            <template slot-scope="scope">
              <span>{{ scope.row | currency("amount") }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="rate"
            label="Rate"
            width="100"></el-table-column>
          <el-table-column prop="amount" label="Quantity" width="120">
            <!-- format number -->
            <template slot-scope="scope">
              <span>{{
                (scope.row.amount / scope.row.rate).toLocaleString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="is_promo" label="Bundle Type" width="115">
            <template slot-scope="scope">
              <span v-if="scope.row.is_promo == 1" style="color: #f56c6c"
                >EXPIRY</span
              >
              <span v-else style="color: #f7921c">NON-EXPIRY</span>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="Description" width="150">
          </el-table-column>
          <el-table-column
            prop="country"
            label="Country"
            width="200"
            class-name="notranslate">
            <template v-slot:header="{ column, $index }">
              <div
                style="display: flex; align-items: center; gap: 10px"
                class="notranslate">
                {{ column.label }}
                <el-select
                  v-model="formData.country"
                  placeholder="Select country"
                  @change="filterHandlerCountry"
                  style="width: 100px"
                  size="mini">
                  <el-option
                    v-for="item in availableCountries"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="user_type_rate"
            label="Account Type"
            :filters="[
              { text: 'Individual', value: 1 },
              { text: 'Corporate', value: 2 },
              { text: 'Both', value: 3 },
            ]"
            :filter-method="filterHandler"
            width="170">
            <template slot-scope="scope">
              <span v-if="scope.row.user_type_rate == 1" style="color: #f56c6c"
                >Individual</span
              >
              <span
                v-else-if="scope.row.user_type_rate == 2"
                style="color: #f7921c"
                >Corporate</span
              >
              <span v-else style="color: #00b4d8">Both</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="expiry_period"
            label="Expiry Period"
            :filters="[
              { text: 'Indefinite', value: 0 },
              { text: 'Monthly Bundle', value: 1 },
              { text: 'Quarterly Bundle', value: 2 },
              { text: 'Half Year Bundle', value: 3 },
            ]"
            :filter-method="filterHandler"
            width="170">
            <template slot-scope="scope">
              <span v-if="scope.row.expiry_period == 0">Indefinite</span>
              <span v-if="scope.row.expiry_period == 1">Monthly Bundle</span>
              <span v-if="scope.row.expiry_period == 2">Quarterly Bundle</span>
              <span v-if="scope.row.expiry_period == 3">Half Year Bundle</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="service_type"
            label="Service Type"
            :filter-method="filterHandler"
            :filters="[
              { text: 'SMS', value: 1 },
              { text: 'Voice', value: 2 },
            ]"
            width="170">
            <template slot-scope="scope">
              <span v-if="scope.row.service_type == 1" style="color: #00b4d8"
                >SMS</span
              >
              <span v-else style="color: #f7921c">Voice</span>
            </template>
          </el-table-column>

          <el-table-column prop="updated_at" label="Updated At" width="170">
            <template slot-scope="scope">
              <span>{{
                moment(scope.row.updated_at).format("ddd DD-MM-YYYY")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updated_at" label="Action" width="200">
            <template slot-scope="scope">
              <div class="button-container">
                <el-button
                  size="mini"
                  type="primary"
                  @click="editRate(scope.row)"
                  >Edit</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="deleteRate(scope.row)"
                  >Delete</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="col-md-12" style="text-align: center; margin-top: 1em">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <AddModifyRateModal
      :showAddModifyRate="showAddModifyRate"
      :form-data="formData"
      @closeModal="hideAddModifyRateModal"></AddModifyRateModal>
    <deleteRateModal
      :rate="deleteRateContent"
      :show-delete-modal="showDeleteRateModal"
      @closeModal="hideDeleteModal"></deleteRateModal>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import { mapGetters } from "vuex";
  import AddModifyRateModal from "./rates/AddModifyRateModal.vue";
  import deleteRateModal from "./rates/deleteRateModal.vue";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  export default {
    components: {
      deleteRateModal,
      AddModifyRateModal,
    },
    data() {
      return {
        formData: {
          amount: "",
          rate: "",
          is_promo: "",
          service_type: "",
          user_type_rate: "",
          description: "",
          expiry_period: "",
          country: "",
        },
        dialogVisible: false,
        buttonloading: false,
        tableLoading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        showAddModifyRate: false,
        deleteRateContent: {},
        showDeleteRateModal: false,

        countries: [
          {
            value: "GH",
            label: "Ghana",
          },
          {
            value: "CI",
            label: "Cote d'Ivoire",
          },
          {
            value: "SN",
            label: "Senegal",
          },
        ],
      };
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      refetchRates(country) {
        this.tableLoading = true;
        store
          .dispatch("admin/rates/getSMSVoiceRate", {
            country: this.formData.country,
          })
          .then((response) => {
            if (response.status === 200) {
              this.tableLoading = false;
            }
          })
          .catch((error) => {
            this.$notify({
              message: "There was an error fetching rates",
              type: "error",
            });
          });
      },
      openAddModifyRate() {
        this.showAddModifyRate = true;
        this.formData.amount = "";
        this.formData.rate = "";
        this.formData.is_promo = "";
        this.formData.service_type = "";
        this.formData.user_type_rate = "";
        this.formData.description = "";
        this.formData.expiry_period = "";
        this.formData.country = "";
      },
      editRate(scope) {
        this.formData.amount = scope.amount;
        this.formData.rate = scope.rate;
        this.formData.is_promo = scope.is_promo;
        this.formData.service_type = scope.service_type;
        this.formData.user_type_rate = scope.user_type_rate;
        this.formData.description = scope.description;
        this.formData.expiry_period = scope.expiry_period;
        this.formData.id = scope.id;
        this.showAddModifyRate = true;
        this.formData.country = scope.country;
      },
      filterHandler(value, row, column) {
        const property = column["property"];
        return row[property] === value;
      },
      filterHandlerCountry(value) {
        // refetch rates
        return this.refetchRates(value);
      },
      deleteRate(scope) {
        this.deleteRateContent = scope;
        this.showDeleteRateModal = true;
      },
      hideAddModifyRateModal() {
        this.showAddModifyRate = false;
        this.refetchRates();
      },
      hideDeleteModal() {
        this.showDeleteRateModal = false;
        this.deleteRateContent = {};
        this.refetchRates();
      },
    },
    mounted() {
      this.formData.country = storage.getSavedState("auth.admin").country || "GH";
      this.refetchRates();
    },
    computed: {
      ...mapGetters({
        rates: "admin/rates/getSMSVoiceRates",
      }),
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
      availableCountries() {
        return this.countries.filter((country) => {
          // if disallowed user show only the country of the user
          if (this.disallowedUsers) {
            return (
              country.value === storage.getSavedState("auth.admin").country
            );
          }
          return true;
        });
      },
    },
    filters: {
      currency(row, key) {
        if (!row) return "";
        if (row.country == "CI") {
          // return amount formatted into CFA
          return row[key].toLocaleString("fr-FR", {
            style: "currency",
            currency: "CFA",
          });
        }
        return row[key].toLocaleString("en-US", {
          style: "currency",
          currency: "GHS",
        });
      },
    },
  };
</script>
<style scoped>
  .main-section {
    display: flex;
    gap: 2rem;
  }

  .right-side {
    width: 100%;
  }

  .right-side,
  .left-side {
    height: 100%;
    background-color: #fff;
    padding: 1rem;
    border-radius: 5px;
  }

  .title {
    font-weight: 600;
  }

  .header {
    font-weight: bold;
    color: #000;
  }

  .show-modify-rate {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-width: fit-content;
  }
</style>
