<template>
  <el-dialog
    :visible.sync="isModalVisible"
    title="Confirm Rate Deletion"
    @close="closeModal"
  >
    <div class="main-content">
      <h6>Are you sure you want to delete the following rate:</h6>

      <el-table :data="[rate]">
        <el-table-column label="Amount" width="150"
          ><span>{{ rate.amount }}</span></el-table-column
        >
        <el-table-column label="Rate" width="150"
          ><span>{{ rate.rate }}</span></el-table-column
        >
        <el-table-column label="Promo Type" width="150">
          <span>{{
            rate.is_promo == 1 ? "Expiry" : "Non-Expiry"
          }}</span></el-table-column
        >
        <el-table-column label="Description" width="150">
          <span>{{ rate.description }}</span></el-table-column
        >
        <el-table-column label="Expiry Period" width="150">
          <span v-if="rate.expiry_period == 0">Indefinite</span>
          <span v-if="rate.expiry_period == 1">Monthly Bundle</span>
          <span v-if="rate.expiry_period == 2">Quarterly Bundle</span>
          <span v-if="rate.expiry_period == 3">Half Year Bundle</span>
        </el-table-column>
        <el-table-column label="User Type" width="120">
          <span>
            {{
              rate.user_type_rate == 1
                ? "Individual"
                : rate.user_type_rate == 2
                ? "Corporate"
                : "Both"
            }}
          </span>
        </el-table-column>
        <el-table-column label="Service Type" width="150">
          <span>{{
            rate.service_type == 1 ? "SMS" : "Voice"
          }}</span></el-table-column
        >
      </el-table>
    </div>
    <template class="" slot="footer">
      <el-button @click="closeModal">Cancel</el-button>
      <el-button :loading="deleteLoading" type="danger" @click="deleteRate"
        >Delete</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        deleteLoading: false,
      };
    },
    props: {
      rate: {
        type: Object,
        required: true,
      },
      showDeleteModal: {
        type: Boolean,
        required: true,
      },
    },
     computed: {
      isModalVisible: {
        get() {
          return this.showDeleteModal;
        },
        set(value) {
          this.$emit("closeModal");
        },
      },
    },
    methods: {
      closeModal() {
        this.$emit("closeModal");
      },
      deleteRate() {
        this.deleteLoading = true;
        if (this.$props.rate.hasOwnProperty("id")) {
          this.$store
            .dispatch("admin/rates/deleteRate", {
              id: this.$props.rate.id,
            })
            .then((data) => {
              this.$message({
                message: "Rate deleted successfully",
                type: "success",
              });
              this.$emit("closeModal");
            })
            .catch((error) => {
              this.$message({
                message: error.response.data.message,
                type: "error",
              });
            })
            .finally(() => {
              this.deleteLoading = false;
            });
        }
      },
    },
  };
</script>
<style scoped>
  .rate-item {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }

  .main-content {
    padding: 20px;
  }

  h6 {
    margin-bottom: 20px;
  }
</style>
